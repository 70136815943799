.ticket.wrapper {
    border: 1px solid #979797;
    font-size: 0.8rem;
}

.ticket.bold {
    font-weight: 700;
}

.ticket.red{
    color: red;
}

.ticket.buttonWrapper{
    margin-top: 1rem;
    text-align: center;
}