.Header {
  text-align: center;
  padding: 20px;
  font-weight: bold;
  background-color: rgb(228, 27, 35);
  color: white;
}

.GreyTop {
  border-top: 12px solid #6c757d;
  padding-top: 2rem;
}

.FormInput {
  width: 400px;
  border: 1px solid #d7d7d7
}

body {
  background: rgb(228, 27, 35);
  background: linear-gradient(90deg, rgba(228, 27, 35, 1) 0%, rgba(228, 27, 35, 1) 3.25%, rgba(255, 255, 255, 1) 3.25%);
}

.PagoPendiente {
  margin: auto;
  background: #6c757d;
  padding: 40px;
  color: white;
  font-weight: bold;
  text-align: center;
}

select {
  line-height: 7rem;
  height: 2.4rem;
  border-radius: 6px;
}

.in-table {
  width: 150px;
}

select {
  -webkit-appearance: auto !important;
  appearance: auto !important;
}

.HeaderProceso {
  font-size: 18px;
  font-weight: bold;
  padding: 20px;
  position: fixed;
  top: 0%;
  z-index: 999;
  left: 17%;
}

.tableDatePicker {
  width: 100px;
}

.tableDatePicker input {
  width: 100px;
}

.paginate {
  display: flex;
  list-style-type: none;
  width: 100%;
  font-size: 17px;
  font-weight: 500;
}

.paginate li{
  display: flex;
  flex-grow: 1;
  padding-top: 10px;
  padding-bottom: 10px;
}

.paginate li a{
  margin: 0 auto;
    text-transform: none;
    color: white !important;
    background-color: #e41b23;
    width: 40px;
    height: 40px;
    text-align: center;
    padding-top: 7px;
    border-radius: 33px;
}

.paginate li.selected a{
    color: #e41b23 !important;
    background-color: #c1c1c1;
}