.menuPrincipal {
    background-color: #e41b23;
    height: 90vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
}

.stripe {
    background-color: #e41b23;
    height: 90vh;
    margin: 45px 0px !important;
    padding: 10px;
    width: auto;
    text-align: center;
}

.stripe svg{
    margin-top: 5px;
    width: 70% !important;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}

.stripe svg:hover{
    color:rgb(243, 243, 243) !important;
    width: 71% !important;
}

.textLogoWrapper {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.logo {
    width: 100px;
}

.menuOpciones{
    list-style: none;
    font-weight: 500;
}

.menuOpciones li{
    margin-top: 20px;
    margin-bottom: 20px;
}

.menuOpciones > li > a:hover{
    color:#e41b23
}
.menuOpciones > li > a{
    text-decoration: none;
    color: #494949;
}

.menuContainer {
    position: absolute;
    left: 3.2vw;
    top: 3vh;
    padding-left: 2vw;
    padding-right: 2vw;
}

.isotipo{
    position: absolute;
    left: 0%;
    top: 0;
}